import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Link, withRouter} from 'react-router-dom';
import uniqid from 'uniqid';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatDateDmy} from '../utils/dates';
import {ieSignUpError, ieSignUpFormShow, ieSignUpFormSubmit, ieSignUpSuccess} from '../actions/creators';
import {isIeSignUpSubmitted, isUserLogged, getUserInfo, getIeSignUpOptions, getCinemas, getIePaymentUrl, getSelectedCinemaId, getIeSignUpUserInfo, getIeCardData, getIeSignUpResult} from '../reducers';
import { sortByName } from '../utils/arrays';
import ExternalPaymentPopup from './checkout/ExternalPaymentPopup';
import { Prompt } from 'react-router-dom';
import IeSignUpOptions from './user/IeSignUpOptions';
import IeSignUpSuccessMessage from './user/IeSignUpSuccessMessage';
import styled from 'styled-components';
import Button from './Button';

const getCinemasChoices = (props, state) => props.cinemas;

const Code = styled.span`
  border: 1px dashed #999;
  display: inline-block;
  margin: 0.25em;
  padding: 0.5em 2em;
`;

const fields = {
  cinema: {
    label: 'Cine favorito',
    required: true,
    id: uniqid(),
    getChoicesMethod: getCinemasChoices,
    validationMessage: 'Selecciona un cine.',
    validationFns: [validators.validateNotEmpty],
  },
  first_name: {
    label: 'Nombres',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu nombre.'
  },
  f_last_name: {
    label: 'Apellido paterno',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu apellido paterno.'
  },
  m_last_name: {
    label: 'Apellido materno',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu apellido materno.'
  },
  birth_date: {
    label: 'Fecha de nacimiento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateBirthDate],
    validationMessage: 'Ingresa tu fecha de nacimiento.',
    placeholder: 'dd/mm/yyyy',
    formatterFn: formatDateDmy
  },
  gender: {
    label: 'Género',
    required: true,
    id: uniqid(),
    placeholder: 'Selecciona tu género',
    choices: [
      {id: 'female', name: 'Femenino'},
      {id: 'male', name: 'Masculino'},
      {id: 'non-binary', name: 'No binarie'},
      {id: 'no-answer', name: 'Prefiero no decir'},
    ],
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona tu género.',
  },
  cellphone: {
    label: 'Celular',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty, validators.validateNumber10Digits],
    validationMessage: 'Ingresa un número de celular de 10 dígitos.'
  },
  terms: {
    label: <React.Fragment>He leído y acepto el <a href="/privacidadvisitantes" target="_blank">Aviso de Privacidad</a></React.Fragment>,
    required: true,
    id: uniqid(),
    type: 'checkbox',
    validationFns: [validators.validateTrue],
    validationMessage: 'Debes aceptar el Aviso de Privacidad para continuar.'
  }
};

const fieldSets = [
  {
    id: 'info',
    label: '',
    fields: ['first_name', 'f_last_name', 'm_last_name', 'birth_date', 'gender', 'cellphone', 'cinema'],
  },
  {
    id: 'terms',
    label: '',
    fields: ['terms'],
  },
];

class IEMigrationPage extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onExternalPaymentComplete = this.onExternalPaymentComplete.bind(this);
    this.onLevelSelect = this.onLevelSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    this.state = {
      level: null,
      cinema: {value: '', valid: null},
      first_name: {value: '', valid: null},
      f_last_name: {value: '', valid: null},
      m_last_name: {value: '', valid: null},
      birth_date: {value: '', valid: null},
      gender: {value: '', valid: null},
      cellphone: {value: '', valid: null},
      terms: {value: false, valid: null},
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.preFillUserInfo();
    this.props.show();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user && (!prevProps.user || this.props.user.id !== prevProps.user.id)
      ||
      this.props.preFillUserInfo && !prevProps.preFillUserInfo
    ) {
      this.preFillUserInfo();
    }
  }

  preFillUserInfo() {
    if (this.props.user) {
      this.setState({
        cinema: {value: (this.props.selectedCinemaId || '').toString(), valid: null},
        gender: {value: this.props.user.gender || '', valid: null},
        birth_date: {value: this.props.user.birth_date || '', valid: null},
        cellphone: {value: this.props.user.mobile_number || '', valid: null}
      });
    }
    
    if (this.props.preFillUserInfo) {
      this.setState({
        first_name: {value: this.props.preFillUserInfo.first_name || '', valid: null},
        f_last_name: {value: this.props.preFillUserInfo.f_last_name || '', valid: null},
        m_last_name: {value: this.props.preFillUserInfo.m_last_name || '', valid: null},
      });
    }
  }

  onLevelSelect(level) {
    const y = this.formRef.current ? this.formRef.current.offsetTop : 300;
    window.scrollTo({top: y, behavior: 'smooth'});
    this.setState({level});
  }

  render() {
    return <React.Fragment>
        <Helmet><title>Cambia tu tarjeta Cinemex Loop - Cinemex</title></Helmet>
        {this.renderContent()}
    </React.Fragment>;
  }

  renderContent() {
    if (!this.props.preFillUserInfo) return '';
    if (this.props.isSubmitted) return this.renderSubmitMessage();
    if (!this.props.isUserLoggedIn) return <p>Por favor, inicia sesión para comenzar.</p>;
    
    return <div>
      {this.props.action === 'ie-migration' ? <React.Fragment>
        <p>CAMBIA TU TARJETA y sigue viviendo la experiencia de ser miembro Cinemex Loop.</p>
        {this.props.preFillUserInfo.current_level !== 'corporate'
          ? <p>Ahora puedes seleccionar mantener o subir tu nivel y podrás disfrutar los <Link to="/loop/niveles-beneficios">beneficios</Link> durante todo el 2023.</p>
          : ''}
      </React.Fragment> : ''}
      {this.props.action === 'ie-renewal' ? <React.Fragment>
        {/* <p>El año pasado acumulaste {this.props.user.visits} visitas a nuestras salas. </p> */}
        <p>Puedes subir o mantener tu nivel, disfrutar los mejores <Link to="/loop/niveles-beneficios">beneficios</Link> y seguir disfrutando las mejores películas.</p>
      </React.Fragment> : ''}
      {this.renderSignUpOptions()}
      <div ref={this.formRef}>{this.state.level ? this.renderForm() : ''}</div>
      {this.state.level ? '' : <p className='small'><Link to="/loop/programa">Conoce más</Link> sobre el programa Cinemex Loop.</p>}
      {this.props.paymentUrl ? this.renderExternalPaymentPopup() : ''}
    </div>
  }

  renderSignUpOptions() {
    if (!this.props.levels) return '';
    
    return <div>
        <IeSignUpOptions levels={this.props.levels} onLevelSelect={this.onLevelSelect} selected={this.state.level} extraWidth />
    </div>;
  }

  renderSubmitMessage() {
    if (this.props.action === 'ie-migration') {
      const content = <React.Fragment>
        <p>Gracias por tu lealtad y por formar parte del mejor programa de recompensas.</p>
        {this.props.result.codes && this.props.result.codes.length ? <React.Fragment>
          <p>
            Por haber realizado el cambio de tu tarjeta Cinemex Loop, te regalamos estos códigos para que puedas redimirlos en nuestro sitio web y aplicaciones por boletos gratis.
          </p>
          <p>{this.props.result.codes.map(code => <Code>{code}</Code>)}</p>
          <p className='checkout-disclaimer'>
            Válido sólo para redención en Cinemex.com y APP Cinemex, de lunes a viernes, en Salas Tradicionales 2D y Premium 2D. Sujeto a disponibilidad de sala. No aplica con otras promociones, descuentos, premieres, preventas, eventos especiales ni contenido alternativo. Prohibida su venta. No aplica para funciones 3D, 4D, Palco, IMAX ni Platino. Una vez canjeado el código en Cinemex.com o App Cinemex, éste pierde su valor. Vigencia al 28 de febrero 2024.
          </p>
        </React.Fragment> : ''}
      </React.Fragment>;
      return <IeSignUpSuccessMessage level={this.props.user.ie_level} title="¡Haz realizado tu cambio de tarjeta Cinemex Loop con éxito! 🥳"
        cardData={this.props.cardData}
        content={content}/>;
    }

    if (this.props.action === 'ie-renewal') {
      return <IeSignUpSuccessMessage level={this.props.user.ie_level} title="Tu cambio de nivel ha sido completado 🤩"
        cardData={this.props.cardData}
        content={<React.Fragment>
          <p>Gracias por tu lealtad y por formar parte del mejor programa de recompensas.</p>
          <p>En caso de requerir una tarjeta física, el costo será de $39 (treinta y nueve pesos 100/00 MN), que incluye un boleto gratis 2D. Puedes solicitarla directamente en el Centro de Atención al Invitado (CAI) de tu Cinemex favorito. Sujeto a disponibilidad del complejo.</p>
        </React.Fragment>}/>;
    }
  }

  renderForm() {
    return (
        <React.Fragment>
          <form noValidate onSubmit={this.onSubmit} className={`${this.state.validated ? 'was-validated' : ''} fade-in mb-5`}>
            {fieldSets.map(fieldSet => (
                <fieldset key={fieldSet.id} >
                  <legend className="subtitle-2">{fieldSet.label}</legend>
                  <div className="form-row">
                    {fieldSet.fields.map(fieldName => {
                      const field = fields[fieldName];
                      const state = this.state[fieldName];

                      return (
                          <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                            <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                      onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                      onBlur={() => this.validate(fieldName)}
                                      value={state.value}
                                      valid={state.valid}
                                      validationError={state.validationError}
                                      inputProps={field.inputProps || {}}
                                      choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, state.value))}
                                      id={field.id}
                                      required={field.required}
                            />
                          </div>
                      );
                    })}
                  </div>
                </fieldset>
            ))}

            <div className="text-right">
              <Button submit primary>Enviar</Button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  renderExternalPaymentPopup() {
    const url = this.props.paymentUrl;

    return <React.Fragment>
      <Prompt when={true} message={'¡Atención! Por favor no abandones esta página mientras está cargando o si ya has dado click en el botón de pagar. Esto no cancelará el cargo y puede que no recibas tu confirmación de compra. ¿Deseas abandonar el proceso de compra de todas formas?'}/>
      {url
          ? <ExternalPaymentPopup url={url} transactionId={this.props.transactionId} onComplete={this.onExternalPaymentComplete} />
          : 'Cargando...'}
    </React.Fragment>;
  }

  onExternalPaymentComplete(res) {
    if (res.success) {
      this.props.onPaymentSuccess(Object.assign({}, res.data, {level: this.state.level.id, is_current: this.state.level.is_current, action: this.props.action}));
    } else {
      this.props.onPaymentError(res.data);
    }
  }

  onChange(prop, value, element) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (field.type === 'checkbox') {
      value = element.checked;
    }

    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value})}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {};

    Object.keys(fields).forEach(field => params[field] = this.state[field].value);

    this.props.submit({
      level: this.state.level,
      info: params
    });
  }

  validateForm() {
    let validForm = true;

    Object.keys(fields).forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
      validForm &= valid;
    });

    return validForm;
  }

  validate(prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
  }

  validateField(prop) {
    const {value} = this.state[prop];
    const {required, validationFns, validationMessage} = fields[prop];
    let valid = true;

    if ((!validationFns || !validationFns.length) || (!value && !required)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }
}

const mapStateToProps = state => ({
  cardData: getIeCardData(state),
  cinemas: getCinemas(state).sort(sortByName),
  isSubmitted: isIeSignUpSubmitted(state),
  isUserLoggedIn: isUserLogged(state),
  levels: getIeSignUpOptions(state),
  paymentUrl: getIePaymentUrl(state),
  preFillUserInfo: getIeSignUpUserInfo(state),
  result: getIeSignUpResult(state),
  selectedCinemaId: getSelectedCinemaId(state),
  user: getUserInfo(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onPaymentError: error => dispatch(ieSignUpError(error)),
  onPaymentSuccess: data => dispatch(ieSignUpSuccess(data)),
  show: () => dispatch(ieSignUpFormShow(props.action)),
  submit: data => dispatch(ieSignUpFormSubmit(data, props.action)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IEMigrationPage));