import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {getBootstrapConfig, getUserInfo, getUserPurchasesFutureOnly} from '../reducers';
import FooterStoresLinks from './FooterStoresLinks';
import {breakpoints} from '../utils/styles';
import { asset } from '../utils/misc';
import { cinemaSelectorOpen } from '../actions/creators/ui';
import { authOpen } from '../actions/creators';
import FooterMobileNav from './FooterMobileNav';
import SocialLinksButtons from './common/SocialLinksButtons';
import "./Footer.css";

const Container = styled.footer`
  color: var(--page-bg);

  body.theme-platinum & {
    color: var(--text-primary);
    background: var(--primary-c) !important;

    .text-white { color: var(--text-primary) !important; }
  }
`;

const Phone = styled.p`
  text-align: center;
  ${() => `@media screen and (min-width: ${breakpoints.desktop}px) { text-align: left; }`}
`;

const PayPalLink = styled.a`
  font-size: 0.7em;
  white-space: nowrap;
  
  &, &:hover { color: #ffffff; }
`;

const FooterLinks = styled.div`
  border-top: 1px solid #6c757d;
  border-bottom: 1px solid #6c757d;
  text-align: center;

  ${() => `@media screen and (min-width: ${breakpoints.desktop}px) {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid #6c757d;
    border-right: 1px solid #6c757d;
    text-align: left;

    body.theme-platinum & { border-color: var(--primary-b-hr); }
  }`}

  & > ul {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
  }
`;

const Logo = styled.img`
    filter: brightness(1.29);
`;

const DevelopedBy = styled.a`
  display: block;
  font-weight: bolder;

  ${() => `@media screen and (min-width: ${breakpoints.desktop}px) {
    display: inline;
  }`}
`;

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.renderFooterContent()}
        <FooterMobileNav user={this.props.user} openCinemaSelector={this.props.openCinemaSelector} openAuthPanel={this.props.openAuthPanel} path={this.props.location.pathname} purchases={this.props.purchases}/>
      </React.Fragment>
    );
  }

  renderFooterContent() {
    if (this.props.includeContent === false) return null;

    return <Container className="py-2 bg-dark" id="footer">
      <div className="container no-width">

        <div className="row no-gutters my-5">
          <div className="col-12 col-lg-4">
            <div className="pr-lg-5 pb-5">
              <Phone className="h1 text-white">
                <small>Atención telefónica</small>
                <br />
                <a href="tel:5552576969" className="text-white"><strong>55 5257-6969</strong></a>
              </Phone>
            </div>
            <div className="pr-lg-5 pt-5 border-top border-secondary">
              <SocialLinksButtons theme='white'/>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <FooterLinks className="px-5 py-5 py-lg-0 my-5 my-lg-0">
              <ul className="font-weight-light small py-2">
                <li className="nav-item"><Link to="/nosotros" className="nav-link py-1 text-white">Sobre Cinemex</Link></li>
                <li className="nav-item"><a className="nav-link py-1 text-white" href="https://webportal.edicomgroup.com/customers/cinemex/search.htm" target="_blank" rel="noopener noreferrer">Factura Electrónica</a></li>
                <li className="nav-item"><Link to="/ventas-corporativas" className="nav-link py-1 text-white">Ventas Corporativas</Link></li>
                <li className="nav-item"><Link to="/landing/terminos-y-condiciones-productos-cinemex/" className="nav-link py-1 text-white">Términos y Condiciones Productos Cinemex</Link></li>
                <li className="nav-item"><Link to="/politica-precios" className="nav-link py-1 text-white">Política de precios</Link></li>
                <li className="nav-item"><Link to="/politica-de-reembolsos" className="nav-link py-1 text-white">Política de reembolsos</Link></li>
                <li className="nav-item"><a className="nav-link py-1 text-white" href="https://atracciondetalento.grupocinemex.com/" target="_blank" rel="noopener noreferrer">Atracción de Talento</a></li>
                <li className="nav-item"><Link to="/terminos" className="nav-link py-1 text-white">Términos y condiciones</Link></li>
                <li className="nav-item"><Link to="/privacidadvisitantes" className="nav-link py-1 text-white">Aviso de Privacidad</Link></li>
              </ul>
            </FooterLinks>
          </div>
          <div className="col-12 col-lg-4">
            <FooterStoresLinks />
            <div className="pt-5 border-top border-secondary">
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-around justify-content-lg-between align-items-center pl-lg-5">
                <a href="http://www.canacine.org.mx/" target="_blank" rel="noopener noreferrer" className="m-2 mb-4">
                  <Logo src={asset('/dist/images/logo-canacine.png')} className="img-fluid" alt="Canacine" />
                </a>
                <a href="http://www.alboa.com.mx/?utm_source=cinemex" target="_blank" rel="noopener noreferrer" className="m-2 mb-4">
                  <Logo src={asset('/dist/images/logo-alboa.png')} className="img-fluid" alt="Alboa" />
                </a>
                <a href="https://gamersarena.com.mx/?utm_source=cinemex" target="_blank" rel="noopener noreferrer" className="m-2 mb-4">
                  <Logo src={asset('/dist/images/logo-arena.png')} className="img-fluid" alt="Gamers Arena" />
                </a>
                <div className="text-center m-2 mb-4" style={{ maxWidth: '115px' }}>
                  <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="Cómo funciona PayPal" onClick={e => { e.preventDefault(); window.open('https://www.paypal.com/webapps/mpp/paypal-popup', 'WIPaypal', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); }} ><img src="https://www.paypalobjects.com/webstatic/mktg/logo-center/logotipo_paypal_seguridad.png" border="0" alt="Aceptamos PayPal" className="img-fluid" /></a>
                  <br />
                  <PayPalLink href="https://www.paypal.com/cl/webapps/mpp/what-is-paypal?locale.x=es_CL">Cómo funciona PayPal</PayPalLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="mt-5 text-center text-lg-right font-weight-light small">
          Derechos reservados &copy; Cadena Méxicana de exhibición S.A de CV2013. <DevelopedBy href="http://socialsnack.com" target="_blank" className="text-white">Sitio desarrollado por SocialSnack.com</DevelopedBy>
        </p>

      </div>
    </Container>;
  }
}

const mapStateToProps = state => ({
  user: getUserInfo(state),
  purchases: getUserPurchasesFutureOnly(state)
});

const mapDispatchToProps = dispatch => ({
  openAuthPanel: () => dispatch(authOpen()),
  openCinemaSelector: () => dispatch(cinemaSelectorOpen()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer));