import * as actions from '../actions/types';
import { getCinema, getSelectedCinema, getUserInfo } from '../reducers';
import { AnalyticsCheckout, AnalyticsUsers } from '../utils/analytics';

export const analyticsMiddleware = ({ getState, dispatch }) => next => async action => {
    next(action);

    switch (action.type) {
        case actions.IE_CODE_SUBMIT_SUCCESS: return AnalyticsUsers.trackIeLink('manual');
        case actions.IE_SIGN_UP_SUCCESS: return ieSignUpSuccess(getState, dispatch, action);
        case actions.AUTH_LOGIN_POST_SUCCESS:
        case actions.AUTH_FB_LOGIN_SUCCESS: return (AnalyticsUsers.trackSignIn() & onUserInfoUpdated(action, getState, dispatch));
        case actions.AUTH_LOGOUT_SUCCESS: return AnalyticsUsers.trackSignOut();
        case actions.IE_NIP_UPDATE_SUCCESS: return AnalyticsUsers.trackIeNipChange();
        case actions.CHECKOUT_TICKET_QTY_INCREASE: return AnalyticsCheckout.trackTicketAdd(action.ticket);
        case actions.CHECKOUT_TICKET_QTY_DECREASE: return AnalyticsCheckout.trackTicketRemove(action.ticket);
        case actions.APP_LOADED:
        case actions.AUTH_LOGOUT_SUCCESS:
        case actions.AUTH_REGISTER_POST_SUCCESS:
        case actions.CHANGE_PREFERRED_CINEMA: return onUserInfoUpdated(action, getState, dispatch);
    }
};

function ieSignUpSuccess(getState, dispatch, {data}) {
    switch (data.action) {
        case 'ie-sign-up': return data.already_registered ? AnalyticsUsers.trackIeLink('sign-up') : AnalyticsUsers.trackIeSignUp(data.level);
        case 'ie-migration': return AnalyticsUsers.trackIeMigration(data.is_current ? 'maintain' : 'upgrade', data.level);
        case 'ie-renewal': return AnalyticsUsers.trackIeRenewal(data.is_current ? 'maintain' : 'upgrade', data.level);
    }
}

function onUserInfoUpdated(action, getState, dispatch) {
    const state = getState();
    const cinema = getSelectedCinema(state);
    const user = getUserInfo(state);

    const data = {
        area: {
            id: null,
            name: null,
        },
        state: {
            id: null,
            name: null,
        },
        cinema: {
            id: null,
            name: null,
            concept: null
        },
        user: {
            type: getUserTypeString(user),
            id: null,
            firstName: null,
            lastName: null,
            birthDate: null,
            phone: null,
            email: null,
            gender: null,
            loyalty: {
                memberId: null,
                level: null,
            },
            preferredCinema: {
                id: null,
                name: null,
                concept: null
            },
        }
    }

    if (cinema) {
        data.cinema = {
            id: cinema.id,
            name: cinema.name,
            concept: getCinemaConceptString(cinema),
        };
        if (cinema.area) {
            data.area = {
                id: cinema.area.id,
                name: cinema.area.name,
            };
        }
        if (cinema.state) {
            data.state = {
                id: cinema.state.id,
                name: cinema.state.name,
            };
        }
    }

    if (user) {
        data.user.id = user.id;
        data.user.firstName = user.first_name;
        data.user.lastName = (user.last_name + ' ' + user.mothers_last_name).replace(/ +/, ' ');
        data.user.birthDate = user.birth_date;
        data.user.phone = user.mobile_number;
        data.user.email = user.email;
        data.user.gender = user.gender;
        data.user.loyalty = {
            memberId: user.iecode,
            level: user.ie_level,
        };

        if (user.cinema_id) {
            const preferredCinema = getCinema(state, user.cinema_id);
            if (preferredCinema) {
                data.user.preferredCinema.id = preferredCinema.id;
                data.user.preferredCinema.name = preferredCinema.name;
                data.user.preferredCinema.concept = getCinemaConceptString(preferredCinema);
            }
        }
    }

    AnalyticsUsers.trackUserInfo(data);
}

function getCinemaConceptString(cinema) {
    if (!cinema) return null;
    if (cinema.platinum === true) return 'platinum';
    if (cinema.market === true) return 'market';
    return 'traditional';
}

function getUserTypeString(user) {
    if (!user || !user.id) return 'guest';
    if (user.iecode) return 'loyalty';
    return 'registered';
}