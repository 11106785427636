import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components';
import {Helmet} from "react-helmet";
import moment from 'moment';
import 'moment/locale/es';
import AboutPage from './components/AboutPage';
import AreaSelector from './components/AreaSelector';
import BillboardPage from './components/billboard/BillboardPage';
import CinemaPage from "./components/cinema/CinemaPage";
import CinemaSelector from "./components/CinemaSelector";
import CheckoutPage from './components/checkout/CheckoutPage';
import CorporatePage from "./components/CorporatePage";
import CorpSalesPage from "./components/CorpSalesPage";
import Dialogs from './components/Dialogs';
import Error404Page from './components/Error404Page';
import HomePage from './components/HomePage';
import IEPage from './components/IEPage';
import Modals from "./components/Modals";
import MovieUpcomingPanel from "./components/MovieUpcomingPanel";
import NewsPage from "./components/NewsPage";
import NewsSingleSidebar from "./components/NewsSingleSidebar";
import NowPage from "./components/now/NowPage";
import PrivacyPage from "./components/PrivacyPage";
import PromosPage from "./components/PromosPage";
import ShowtimeSelector from "./components/ShowtimeSelector";
import AuthPanel from "./components/AuthPanel";
import UserPage from "./components/user/UserPage";
import ContactPage from "./components/ContactPage";
import LandingPage from "./components/LandingPage";
import {moviesFetch,moviesUpcomingFetch,fingerprintSet, appLoaded} from './actions/creators';
import {getSelectedCinemaId,getSelectedArea,isUiCinemaSelectorOpen, isUiPostRegisterPopupOpen, isIeCodePanelVisible, getPostPurchaseIePopupData, isAuthPinDialogOpen, isSomethingLoading, getSelectedCinema} from './reducers';
import './App.css';
import { zIndexes } from './utils/styles';
import ErrorBoundary from './components/ErrorBoundary';
import MessagePage from './components/MessagePage';
import NotificationsBridge from './components/NotificationsBridge';
import AppsRedirect from './components/AppsRedirect';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import AuthPinDialog from './components/AuthPinDialog';
import LogoLoading from './components/LogoLoading';
import PostRegisterPopup from './components/PostRegisterPopup';
import IeCodePanel from './components/checkout/IeCodePanel';
import UserIeCard from './components/user/UserIeCard';
import IeRedirection from './components/user/IeRedirection';
import { loadStripe } from '@stripe/stripe-js';

const theme = {
  zIndexes
};

const StyledApp = styled.div`
  .l-wrapper__sidebar > div { height: 100%; }

  ${props => props.cinema && props.cinema.id === 393 && `
    
    `}
`;

class App extends Component {
  constructor(props) {
    super(props);

    moment.locale('es');
  }

  componentDidMount() {
    this.props.fetchMovies();
    
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        const visitorId = result.visitorId;
        this.props.fingerprintSet(visitorId);
      }).then(() => {
        this.props.appLoaded();
      });
    });

    window.localConfig.stripe.promise = loadStripe(window.localConfig.stripe.publicKey);
  }

  render() {
    return (
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorBoundary>
              <StyledApp cinema={this.props.cinema}>
                <Helmet>
                  <title>Cinemex</title>
                  <meta name="description" content="Cadena de cines en México. Presenta información relevante sobre cartelera, salas, películas en exhibición, concursos y mucho más." />
                </Helmet>

                <Switch>
                  <Route path="/por-comenzar/cine-:id(\d+)/:slug/:format(formato-[a-z0-9,_]+)?/:lang(idioma-[a-z,]+)?/:time_limit(minutos-\d+)?" component={NowPage}/>
                  <Route path="/cartelera/:areaType-:area(\d+)/:slug/:date(fecha-\d+)?/:format(formato-[a-z0-9,_]+)?/:lang(idioma-[a-z,]+)?/:hours(horas-\d+a\d+)?/:cinema(cine-\d+)?/:movie(pelicula-\d+)?" component={BillboardPage}/>
                  <Route path="/cine/:id/:slug/:date(fecha-\d+)?/:format(formato-[a-z0-9,_]+)?/:lang(idioma-[a-z,]+)?/:hours(horas-\d+a\d+)?/:movie(pelicula-\d+)?" component={CinemaPage}/>
                  <Route path="/checkout/:id" component={CheckoutPage}/>
                  <Route path="/usuario/loop/redireccion" component={IeRedirection} />
                  <Route path="/usuario/loop/tarjeta" component={UserIeCard} />
                  <Route path="/usuario" component={UserPage}/>
                  <Route path="/contacto" component={ContactPage}/>
                  <Route path="/novedades/:id(\d+)?/:slug?" component={NewsPage}/>
                  <Route path="/promociones/:id(\d+)?/:slug?" component={PromosPage}/>
                  <Route path={["/nosotros","/empleos/formulario"]} component={AboutPage}/>
                  <Route path={["/ventas-corporativas"]} component={CorpSalesPage}/>
                  <Route path={["/terminos","/politica-precios","/politica-de-reembolsos","/comunicados","/terminos-y-condiciones-de-compra"]} exact={true} component={CorporatePage}/>
                  <Route path={["/privacidadvisitantes","/privacidadfacturacion","/privacidadproveedores","/privacidadcandidatos","/privacidadexempleados",'/arco/formulario']} exact={true} component={PrivacyPage}/>
                  <Route path="/loop" component={IEPage}/>
                  <Route path="/landing/:id" component={LandingPage}/>
                  <Route path={["/", "/pelicula/:id/:slug", "/proximamente/:id/:slug"]} exact={true} component={HomePage}/>
                  <Route path="/:page(vincular-ie|desvincular-ie|confirmacion-email|cuenta-eliminada|cambio-email)/:result(error|exito)?" exact={true} component={MessagePage}/>
                  <Route path="/descarga-la-app" exact={true} component={AppsRedirect}/>
                  <Route component={Error404Page}/>
                </Switch>

                <Route path="/pelicula/:id/:slug?" render={props => (
                    <ShowtimeSelector
                        cinemaId={this.props.cinemaId}
                        movieId={props.match.params.id}
                        open={this.props.side}
                    />
                )} />
                <Route path="/landing/:slugs*/pelicula/:id/:slug" render={props => (
                    <ShowtimeSelector
                        cinemaId={this.props.cinemaId}
                        movieId={props.match.params.id}
                        open={this.props.side}
                    />
                )} />
                <Route path="/proximamente/:id/:slug" component={MovieUpcomingPanel}/>
                <Route path="/novedades/:id(\d+)/:slug" component={NewsSingleSidebar}/>

                <CinemaSelector
                    open={this.props.isCinemaSelectorOpen}
                    areaId={this.props.area && this.props.area.id}
                    cinemaId={this.props.cinemaId}
                />
                <AreaSelector/>

                <Switch>
                  <Route path="/completar-registro/:token" render={(props) => <AuthPanel {...props} cleanRoute={true} open={true} panel="complete-sign-up"/>}/>
                  <Route path="/restablecer-password/" render={(props) => <AuthPanel {...props} cleanRoute={true} open={true} panel="reset-pass"/>}/>
                  <Route path="/registro" render={(props) => <AuthPanel {...props} cleanRoute={true} open={true} panel="register"/>}/>
                  <Route path="/login/" render={(props) => <AuthPanel {...props} cleanRoute={true} open={true} panel="login"/>}/>
                  <Route path="/" component={AuthPanel}/>
                </Switch>

                <Dialogs/>
                <NotificationsBridge/>

                {this.props.isPostRegisterPopupOpen ? <PostRegisterPopup purchase={this.props.postPurchaseIePopupData}/> : null}
                {this.props.isIeCodePanelOpen ? <IeCodePanel/> : null}
                {this.props.isAuthPinDialogOpen ? <AuthPinDialog/> : ''}
                {this.props.renderModals ? <Route component={Modals}/> : null}
                {this.props.isSomethingLoading ? <div style={{zIndex:11000,position:'fixed'}}><LogoLoading/></div> : ''}

              </StyledApp>
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  area: getSelectedArea(state),
  cinema: getSelectedCinema(state),
  cinemaId: getSelectedCinemaId(state),
  isAuthPinDialogOpen: isAuthPinDialogOpen(state),
  isCinemaSelectorOpen: isUiCinemaSelectorOpen(state),
  isSomethingLoading: isSomethingLoading(state),
  isPostRegisterPopupOpen: isUiPostRegisterPopupOpen(state),
  isIeCodePanelOpen: isIeCodePanelVisible(state),
  postPurchaseIePopupData: getPostPurchaseIePopupData(state),
  renderModals: !isUiCinemaSelectorOpen(state) && !isUiPostRegisterPopupOpen(state) && !isIeCodePanelVisible(state) && !isAuthPinDialogOpen(state)
});

const mapDispatchToProps = dispatch => ({
  appLoaded: () => dispatch(appLoaded()),
  fetchMovies: () => dispatch(moviesFetch()),
  fetchMoviesUpcoming: () => dispatch(moviesUpcomingFetch()),
  fingerprintSet: id => dispatch(fingerprintSet(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
