import {centsToCurrencyString} from './numbers';

export const parseOrderAmountByPaymentMethod = option => {
  const amountByMethod = {};

  Object.keys(option).forEach(itemType => {
    if (itemType === 'booking_fee') return;
    
    const type = option[itemType];

    type.forEach(item => {
      const method = item.payment.id;
      if (!(method in amountByMethod)) {
        amountByMethod[method] = 0;
      }
      amountByMethod[method] += item.payment.value;
    });
  });

  return amountByMethod;
};

export const getOptionIcons = option => {
  const result = [];
  const methods = getPaymentOptionActualPaymentMethods(option);
  const cash = methods.indexOf('cash') > -1;
  const ie = (methods.indexOf('ie') > -1) || (methods.indexOf('passes') > -1);

  if (cash) result.push('/dist/images/cashcard.jpg');
  if (ie) result.push('/dist/images/iecard.png');

  return result;
};

const getPaymentOptionActualPaymentMethods = option => {
  const amountByMethod = parseOrderAmountByPaymentMethod(option);
  const result = [];
  
  Object.keys(amountByMethod).forEach(method => {
    if (amountByMethod[method] > 0) {
      result.push(method);
    }
  });

  return result;
};

export const getOptionLabel = option => getPaymentString(parseOrderAmountByPaymentMethod(option));

export const getTicketsPaymentString = (ticketsOrder, bookingFee) => {
  console.log('getTicketsPaymentString', ticketsOrder, bookingFee);
  const byMethod = {};
  ticketsOrder.forEach(ticket => {
    if (!ticket.qty) return;
    let amount = 0;
    let method = '';
    if (!('payment' in ticket)) {
      method = 'cash';
      amount = ticket.price;
    } else {
      method = ticket.payment.id;
      amount = ticket.payment.value;
    }

    if (!(method in byMethod)) byMethod[method] = 0;
    byMethod[method] += amount;
  });
  
  if (('cash' in byMethod) && byMethod['cash'] > 0 && bookingFee) {
    byMethod.cash -= bookingFee;
    byMethod.cash = Math.max(0, byMethod.cash);
  }

  return getPaymentString(byMethod);
};

export const getPaymentString = amountByMethod => {
  let result = [];

  Object.keys(amountByMethod).forEach(method => {
    result.push(getMethodValueLabel(method, amountByMethod[method]));
  });

  result = (function removeZeroDolarsFromCombinedPayments(result) {
    const i = result.indexOf('$0.00');

    if (result.length > 1 && i > -1) {
      result.splice(i, 1);
    }

    return result;
  })(result);

  return result.join(' + ');
};

export const getMethodValueLabel = (method, amount) => {
  if ('cash' === method || 'gift-cert' === method) {
    return centsToCurrencyString(amount);
  } else {
    return amount + ' ' + getMethodLabelPart(method, amount);
  }
};

export const getMethodLabelPart = (method, amount) => {
  switch (method) {
    case 'ie':
      return getIEValueLabel(amount);
    case 'passes':
      return getPassesValueLabel(amount);
    default:
      return '';
  }
};

const getIEValueLabel = amount => {
  return 'punto' + (amount !== 1 ? 's' : '');
};

const getPassesValueLabel = amount => {
  return (amount !== 1 ? 'pases corporativos' : 'pase corporativo');
};

export const isIeNipRequired = option => {
  const methods = getPaymentOptionActualPaymentMethods(option);

  return (methods.indexOf('ie') > -1) || (methods.indexOf('passes') > -1);
};

export const getNextStep = (currentStep, action) => {
  switch (currentStep) {
    case 'tickets':
      return action.session.seatallocation ? 'seats' : getNextStep('seats', action);
    case 'seats':
      return action.cinema.candybar ? 'candybar' : getNextStep('candybar', action);
    case 'candybar':
      return getCandybarNextStep(action);
    case 'payment-suggestions':
      return 'payment';
    case 'payment':
      return 'confirmation';
    default:
      return currentStep;
  }
};

function getCandybarNextStep(action) {
  if (!action.skipPaymentOptions) {
    return 'payment-suggestions';
  }
  
  return getNextStep('payment-suggestions', action);
}

export const getPrevStep = (currentStep, action) => {
  switch (currentStep) {
    case 'payment':
      return (!action.skipPaymentOptions) ? 'payment-suggestions' : getPrevStep('payment-suggestions', action);
    case 'payment-suggestions':
      return action.cinema.candybar ? 'candybar' : getPrevStep('candybar', action);
    case 'candybar':
      return action.session.seatallocation ? 'seats' : getPrevStep('seats', action);
    case 'seats':
      return 'tickets';
    default:
      return currentStep;
  }
};

export const getTicketsParam = (entities, order) => {
  const tickets = [];

  Object.keys(order).forEach(ticketId => {
    const ticket = entities[ticketId];
    const item = order[ticketId];

    if (!item.qty) { return; }

    tickets.push({
      type: ticket.id,
      price: ticket.price,
      qty: item.qty,
      extra: ticket.extra
    });
  });

  return tickets;
};

export const getCashPaymentMethodsAllowed = (entities, order) => {
  let result = ['moviecard', 'masterpass', 'paypal', 'credit-card'];
  
  if (isMasterpassTicketPresent(entities, order)) {
    result = result.filter(method => method === 'masterpass');
  }

  return result;
};

const isMasterpassTicketPresent = (entities, order) => {
  for (let ticketId in order) {
    const ticket = entities[ticketId];
    const item = order[ticketId];

    if (item.qty && (ticket.extra.masterpass || ticket.extra.master_card_pass)) {
      return true;
    }
  }

  return false;
};

export const maskCardNumber = cc => {
  cc = cc + '';
  cc = cc.replace(/[^\d]+/g, '');
  const len = cc.length;

  return cc.substr(0, 6) + '0'.repeat(len - 10) + cc.substr(-4);
};