import {AUTH_SESSION_EXPIRED} from '../actions/types';

const environments = {
  localMock: {
    appId: 'cmxdevkey',
    host: 'https://192.168.0.115:8080/',
    hostAuth: false,
    path: 'rest/v2.29/',
    xdebug: false,
  },
  localSymfony: {
    appId: 'XXQha7vz4kdvoMSdixhN',
    host: 'https://api.cinemex.local:8083/app_dev.php/',
    hostAuth: false,
    path: 'rest/v2.31/',
    xdebug: true,
  },
  cinemexProd: {
    appId: 'XXQha7vz4kdvoMSdixhN',
    host: 'https://api.cinemex.com/',
    hostAuth: false,
    path: 'rest/v2.31/',
    xdebug: false,
  },
  staging8: {
    appId: 'XXQha7vz4kdvoMSdixhN',
    host: 'https://api-staging8.cinemex.com/',
    hostAuth: 'Basic Y2luZV9hZG06MjlWQ1NlNHVLOExBUw==',
    path: 'rest/v2.31/',
    xdebug: false,
  }
};

const env = environments[window.localConfig.environment];

const {appId, host, path} = env;
const baseUrl = host + path;
const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};
let reduxStore;

class ApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiError";
  }
}

const getUrl = endpoint => {
  if (baseUrl.substr(-1) === '/' && endpoint.substr(0, 1) === '/') {
    endpoint = endpoint.substr(1);
  }

  return baseUrl + endpoint + (env.xdebug ? (endpoint.indexOf('?') > -1 ? '&' : '?') + 'XDEBUG_SESSION_START=PHPSTORM' : '');
};

const request = (method, endpoint, params, files) => {
  let status = 0;
  let success = false;
  let body = null;

  const headers = {
    'X-API-Consumer-Key': appId
  };

  if (env.hostAuth) {
    headers['Authorization'] = env.hostAuth;
  }

  if (method === methods.post || method === methods.put || method === methods.delete) {
    if (files) {

      const data = new FormData();
      if (params) {
        for (let key in params) {
          data.append(key, params[key]);
        }
      }
      if (files) {
        for (let key in files) {
          data.append(key, files[key]);
        }
      }
      body = data;
    } else {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(params);
    }
  }

  const withAuth = shouldIncludeAuth(endpoint) && reduxStore && reduxStore.getState().user.token;
  if (withAuth) {
    headers['X-Authorization'] = 'token ' + reduxStore.getState().user.token;
  }

  return fetch(
      getUrl(endpoint),
      {
        body,
        headers,
        method,
        mode: 'cors',
        credentials: 'include'
      }
  )
      .then(response => {
        status = response.status;
        success = response.ok;

        return status === 204 ? {} : response.json()
      })
      .then(json => {
        if (!success || status >= 400) {
          let error;

          if (status === 401 && withAuth) {
            reduxStore.dispatch({
              type: AUTH_SESSION_EXPIRED
            });
            error = new ApiError('Tu sesión ha expirado. Por favor, ingresa nuevamente');
          } else {
            error = new ApiError(json.message || 'Ocurrió un error');
            error.response = json;
          }

          error.status = status;
          throw error;
        }

        return json;
      })
      .catch(error => {
        console.log(error);
        if (error.name !== 'ApiError') {
          error.message = 'Ocurrió un error. Por favor, inténtalo nuevamente.';
        }

        throw error;
      });
};

const shouldIncludeAuth = endpoint => {
  return endpoint.match(/^\/?(me\/|buy\/|ie\/payment)/);
};

const CMX = {
  get(endpoint, params) {
    if (params) {
      if (endpoint.indexOf('?') > -1) {
        endpoint += '&';
      } else {
        endpoint += '?';
      }

      const parts = [];
      for (let k in params) {
        let v = params[k];
        if (v || v === false) {
          parts.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
      }

      endpoint += parts.join('&');
    }
    return request(methods.get, endpoint);
  },

  post(endpoint, params, files) {
    const match = document.location.href.match(/\bforce_gateway=([a-z0-9]+)\b/);
    
    if (params && match) {
      params.force_gateway = match[1];
    }

    return request(methods.post, endpoint, params, files);
  },

  put(endpoint, params) {
    return request(methods.put, endpoint, params);
  },

  delete(endpoint, params) {
    return request(methods.delete, endpoint, params);
  },

  getMasterpassWalletUrl(transactionId, sessionId, key) {
    return host + 'masterpass/goToWallet'
        + '?transaction_id=' + transactionId
        + '&session_id=' + sessionId
        + '&key=' + encodeURIComponent(key)
        + '&from=web_v2';
  },

  getAppleSigninRedirectUrl() {
    return host + 'apple-signin/callback#app_id=' + env.appId;
  },

  setReduxStore(store) {
    reduxStore = store;
  }
};

export default CMX;