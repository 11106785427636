import React from 'react';
import {connect} from 'react-redux';
import uniqid from 'uniqid';
import Blocker from "./Blocker";
import Button from './Button';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatDateDmy} from '../utils/dates';
import {getAreas,getCinemas,getStates,isJobsFormProcessing,isJobsFormSubmitted} from '../reducers';
import {jobsFormShow,jobsFormSubmit} from '../actions/creators';
import {sortByName} from '../utils/arrays';

const getAreasChoices = props => props.areas;
const getStatesChoices = props => props.states;
const getAreaCinemasChoices = (props, state) => props.cinemas.filter(cinema => cinema.area.id == state.area_state_value);
const getFamilyRelationships = (props, state, fieldName, fieldValue) => {
  const selected = [];

  for (let i = 1; i <= 7; i++) {
    const name = 'family_' + i + 'relationship';
    if (name === fieldName) {
      continue;
    }

    selected.push(state[name + '_value']);
  }

  const options = [
    {id: 'padre', name: 'Padre'},
    {id: 'madre', name: 'Madre'},
    {id: 'conyugue', name: 'Cónyugue'},
    {id: 'hermano', name: 'Hermano'},
    {id: 'hijo', name: 'Hijo'},
  ];

  return options.filter(option => {
    const i = selected.indexOf(option.id);
    if (i === -1) { return true; }
    else if (option.id === 'hermano' && selected.lastIndexOf(option.id) === i) { return true; }
    else if (option.id === 'hijo' && selected.lastIndexOf(option.id) === i) { return true; }

    return false;
  });
};

const fields = {
  name: {
    label: 'Nombre completo',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu nombre.'
  },
  address: {
    label: 'Calle y número',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu dirección.'
  },
  colony: {
    label: 'Colonia',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu colonia.'
  },
  zip_code: {
    label: 'Código postal',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty, validators.validateNumber5Digits],
    validationMessage: 'Ingresa tu código postal.'
  },
  state: {
    label: 'Estado',
    required: true,
    id: uniqid(),
    getChoicesMethod: getStatesChoices,
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona tu estado.'
  },
  city: {
    label: 'Municipio, Delegación o Ciudad',
    id: uniqid(),
  },
  cellphone: {
    label: 'Teléfono celular',
    id: uniqid(),
  },
  phone: {
    label: 'Teléfono',
    id: uniqid(),
  },
  email: {
    label: 'Email',
    type: 'email',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateEmail],
    validationMessage: 'Ingresa tu email.'
  },
  birth_date: {
    label: 'Fecha de nacimiento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateBirthDate],
    validationMessage: 'Ingresa tu fecha de nacimiento.',
    placeholder: 'dd/mm/yyyy',
    formatterFn: formatDateDmy
  },
  marital_status: {
    label: 'Estado civil',
    required: true,
    id: uniqid(),
    choices: [
        {id: 'soltero', name: 'Soltero'},
        {id: 'casado', name: 'Casado'},
        {id: 'divorciado', name: 'Divorciado'},
        {id: 'viudo', name: 'Viudo'},
    ],
    // placeholder: 'Selecciona tu estado civil',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona tu estado civil.'
  },
  have_children: {
    label: '¿Tienes hijos?',
    required: true,
    id: uniqid(),
    choices: [
        {id: 'si', name: 'Sí'},
        {id: 'no', name: 'No'},
    ],
    // placeholder: 'Selecciona una opción',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona una opción.'
  },
  number_imss: {
    label: 'Numero de pre afiliación o IMSS',
    id: uniqid(),
  },
  number_rfc: {
    label: 'RFC',
    id: uniqid(),
  },
  number_curp: {
    label: 'CURP',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu CURP.'
  },
  area_available_trips: {
    label: 'Disponible para viajar',
    id: uniqid(),
    type: 'checkbox',
  },
  area_available_move: {
    label: 'Disponible cambio de residencia',
    id: uniqid(),
    type: 'checkbox',
  },
  area_type: {
    label: '¿Dónde quieres trabajar?',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'cafes', name: 'Cafés'},
      {id: 'cines', name: 'Cines'},
    ],
    // placeholder: 'Selecciona una opción',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona una opción.',
  },
  area_cafe: {
    label: 'Complejo de interés',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'CAFÉ CENTRAL GOURMET (ITAM)', name: 'Café Ccentral Gourmet (ITAM)'},
      {id: 'CAFÉ CENTRAL GOURMET SANTA FE', name: 'Café Central Gourmet Santa Fé'},
      {id: 'CAFÉ CENTRAL GOURMET PARQUE REFORMA', name: 'Café Central Gourmet Parque Reforma'},
      {id: 'CAFÉ CENTRAL GOURMET GALERÍAS MAZATLÁN', name: 'Café Central Gourmet Galerías Mazatlán'},
    ],
    // placeholder: 'Selecciona un café',
    validationMessage: 'Selecciona un café.',
    validationFns: [validators.validateNotEmpty],
  },
  area_state: {
    label: 'Selecciona tu ciudad',
    required: true,
    id: uniqid(),
    getChoicesMethod: getAreasChoices,
    // placeholder: 'Selecciona tu ciudad',
    validationMessage: 'Selecciona tu ciudad.',
    validationFns: [validators.validateNotEmpty],
  },
  area_cinema: {
    label: 'Complejo de interés',
    required: true,
    id: uniqid(),
    getChoicesMethod: getAreaCinemasChoices,
    // placeholder: 'Selecciona un cine',
    validationMessage: 'Selecciona un cine.',
    validationFns: [validators.validateNotEmpty],
  },
  area_name: {
    label: 'Área de interés',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'Gerente de cine', name: 'Gerente de cine'},
      {id: 'Staff Multifuncional', name: 'Staff Multifuncional'},
      {id: 'Mantenimiento', name: 'Mantenimiento'},
    ],
    // placeholder: 'Selecciona un área',
    validationMessage: 'Selecciona un área.',
    validationFns: [validators.validateNotEmpty],
  },
  school_grade: {
    label: 'Último grado de estudios',
  },
  school_actual: {
    label: 'Estudios actuales'
  },
  experience_1company: {
    label: 'Nombre de la empresa'
  },
  experience_1position: {
    label: 'Nombre del puesto'
  },
  experience_1period: {
    label: 'Período'
  },
  experience_1salary: {
    label: 'Sueldo'
  },
  experience_1exit_reason: {
    label: 'Motivo de salida'
  },
  experience_2company: {
    label: 'Nombre de la empresa'
  },
  experience_2position: {
    label: 'Nombre del puesto'
  },
  experience_2period: {
    label: 'Período'
  },
  experience_2salary: {
    label: 'Sueldo'
  },
  experience_2exit_reason: {
    label: 'Motivo de salida'
  },
  experience_3company: {
    label: 'Nombre de la empresa'
  },
  experience_3position: {
    label: 'Nombre del puesto'
  },
  experience_3period: {
    label: 'Período'
  },
  experience_3salary: {
    label: 'Sueldo'
  },
  experience_3exit_reason: {
    label: 'Motivo de salida'
  },
  family_1relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_1name: {
    label: 'Nombre'
  },
  family_1age: {
    label: 'Edad'
  },
  family_1job: {
    label: 'Ocupación'
  },
  family_1company: {
    label: 'Empresa'
  },
  family_2relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_2name: {
    label: 'Nombre'
  },
  family_2age: {
    label: 'Edad'
  },
  family_2job: {
    label: 'Ocupación'
  },
  family_2company: {
    label: 'Empresa'
  },
  family_3relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_3name: {
    label: 'Nombre'
  },
  family_3age: {
    label: 'Edad'
  },
  family_3job: {
    label: 'Ocupación'
  },
  family_3company: {
    label: 'Empresa'
  },
  family_4relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_4name: {
    label: 'Nombre'
  },
  family_4age: {
    label: 'Edad'
  },
  family_4job: {
    label: 'Ocupación'
  },
  family_4company: {
    label: 'Empresa'
  },
  family_5relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_5name: {
    label: 'Nombre'
  },
  family_5age: {
    label: 'Edad'
  },
  family_5job: {
    label: 'Ocupación'
  },
  family_5company: {
    label: 'Empresa'
  },
  family_6relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_6name: {
    label: 'Nombre'
  },
  family_6age: {
    label: 'Edad'
  },
  family_6job: {
    label: 'Ocupación'
  },
  family_6company: {
    label: 'Empresa'
  },
  family_7relationship: {
    label: 'Parentesco',
    getChoicesMethod: getFamilyRelationships,
  },
  family_7name: {
    label: 'Nombre'
  },
  family_7age: {
    label: 'Edad'
  },
  family_7job: {
    label: 'Ocupación'
  },
  family_7company: {
    label: 'Empresa'
  },
  family_add: {
    label: 'Agregar familiar',
    type: 'button',
  },
  family_cinemex: {
    label: '¿Tienes familia trabajando en Cinemex?',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'si', name: 'Sí'},
      {id: 'no', name: 'No'},
    ],
    // placeholder: 'Selecciona una opción',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona una opción.',
  },
  terms: {
    label: <React.Fragment>He leído y acepto el <a href="/privacidadcandidatos" target="_blank">Aviso de Privacidad</a></React.Fragment>,
    required: true,
    id: uniqid(),
    type: 'checkbox',
    validationFns: [validators.validateTrue],
    validationMessage: 'Debes aceptar el Aviso de Privacidad para continuar.'
  }
};

const fieldSets = [
  {
    label: 'Información general',
    fields: ['name', 'address', 'colony', 'zip_code', 'state', 'city', 'cellphone', 'phone', 'email', 'birth_date', 'marital_status', 'have_children', 'number_imss', 'number_rfc', 'number_curp'],
  },
  {
    label: 'Área de interés',
    fields: ['area_available_trips', 'area_available_move', 'area_type', 'area_cafe', 'area_state', 'area_cinema', 'area_name'],
  },
  {
    label: 'Escolaridad',
    fields: ['school_grade', 'school_actual'],
  },
  {
    label: 'Trabajos anteriores - #1',
    fields: ['experience_1company', 'experience_1position', 'experience_1period', 'experience_1salary', 'experience_1exit_reason'],
  },
  {
    label: 'Trabajos anteriores - #2',
    fields: ['experience_2company', 'experience_2position', 'experience_2period', 'experience_2salary', 'experience_2exit_reason'],
  },
  {
    label: 'Trabajos anteriores - #3',
    fields: ['experience_3company', 'experience_3position', 'experience_3period', 'experience_3salary', 'experience_3exit_reason'],
  },
  {
    label: 'Datos familiares - #1',
    fields: ['family_1relationship', 'family_1name', 'family_1age', 'family_1job', 'family_1company'],
  },
  {
    label: 'Datos familiares - #2',
    fields: ['family_2relationship', 'family_2name', 'family_2age', 'family_2job', 'family_2company'],
  },
  {
    label: 'Datos familiares - #3',
    fields: ['family_3relationship', 'family_3name', 'family_3age', 'family_3job', 'family_3company'],
  },
  {
    label: 'Datos familiares - #4',
    fields: ['family_4relationship', 'family_4name', 'family_4age', 'family_4job', 'family_4company'],
  },
  {
    label: 'Datos familiares - #5',
    fields: ['family_5relationship', 'family_5name', 'family_5age', 'family_5job', 'family_5company'],
  },
  {
    label: 'Datos familiares - #6',
    fields: ['family_6relationship', 'family_6name', 'family_6age', 'family_6job', 'family_6company'],
  },
  {
    label: 'Datos familiares - #7',
    fields: ['family_7relationship', 'family_7name', 'family_7age', 'family_7job', 'family_7company'],
  },
  {
    label: 'Datos familiares',
    fields: ['family_cinemex'],
  },
  {
    label: '',
    fields: ['terms'],
  },
];

class JobsForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    const state = {};

    Object.keys(fields).forEach(key => {
      state[key + '_value'] = fields[key].type === 'checkbox' ? false : '';
      state[key + '_valid'] = null;
    });

    state['area_cafe_visible'] = false;
    state['area_state_visible'] = false;
    state['area_cinema_visible'] = false;
    state['area_name_visible'] = false;

    for (let i = 2; i <= 7; i++) {
      // state['family_' + i + 'relationship'] = false;
      state['family_' + i + 'name_visible'] = false;
      state['family_' + i + 'age_visible'] = false;
      state['family_' + i + 'job_visible'] = false;
      state['family_' + i + 'company_visible'] = false;
    }

    this.state = state;
  }

  componentDidMount() {
    this.props.show();
  }

  componentDidUpdate(prevProps, prevState) {
    const areaTypeChanged = prevState.area_type_value !== this.state.area_type_value;
    const areaStateChanged = prevState.area_state_value !== this.state.area_state_value;
    const areaCinemaChanged = prevState.area_cinema_value !== this.state.area_cinema_value;

    if (areaTypeChanged || areaStateChanged || areaCinemaChanged) {
      const isCafes = this.state.area_type_value === 'cafes';
      const isCinema = this.state.area_type_value === 'cines';
      const isStateSelected = !!this.state.area_state_value;
      const isCinemaSelected = !!this.state.area_cinema_value;

      this.setState(state => ({
        area_cafe_visible: isCafes,
        area_state_visible: isCinema,
        area_cinema_visible: isCinema && isStateSelected,
        area_name_visible: isCinema && isCinemaSelected,
      }));

      if (areaStateChanged) {
        this.setState(state => ({
          area_cinema_value: '',
          area_cinema_valid: null
        }));
      }
    }

    for (let i = 2; i <= 7; i++) {
      const idBase = 'family_' + i;
      const id = idBase + 'relationship_value';
      const familyChanged = prevState[id] !== this.state[id];
      const relationshipSelected = !!this.state[id];

      if (familyChanged) {
        this.setState(() => ({
          [idBase + 'name_visible']: relationshipSelected,
          [idBase + 'age_visible']: relationshipSelected,
          [idBase + 'job_visible']: relationshipSelected,
          [idBase + 'company_visible']: relationshipSelected,
        }));
      }
    }
  }

  render() {
    return this.props.isSubmitted ? this.renderSubmitMessage() : this.renderForm();
  }

  renderSubmitMessage() {
    return (
        <div className="row fade-in">
          <div className="col-12 col-lg-7">
            <p><i className="fal fa-envelope-open fa-4x text-primary"></i></p>
            <h1 className="h2">¡La solicitud se ha enviado con éxito!</h1>
            <p className="text-secondary mb-5">
              {this.state.area_type_value === 'cafes' ? (
                  <React.Fragment>Gracias por querer ser parte del grupo; el proceso de selección puede tardar alrededor de dos semanas, si requieres de mayor información, solicítala enviando un correo a <a href="mailto:&#99;&#97;&#102;&#101;&#99;&#101;&#110;&#116;&#114;&#97;&#108;&#103;&#111;&#117;&#114;&#109;&#101;&#116;&#64;&#99;&#105;&#110;&#101;&#109;&#101;&#120;&#46;&#110;&#101;&#116;">&#99;&#97;&#102;&#101;&#99;&#101;&#110;&#116;&#114;&#97;&#108;&#103;&#111;&#117;&#114;&#109;&#101;&#116;&#64;&#99;&#105;&#110;&#101;&#109;&#101;&#120;&#46;&#110;&#101;&#116;</a>.</React.Fragment>
              ) : (
                  <React.Fragment>Gracias por querer ser parte de nuestro equipo; el proceso de selección puede tardar alrededor de dos semanas, si requieres de mayor información, solicítala en el Cinemex que elegiste o envía un correo a <a href="mailto:&#98;&#117;&#122;&#111;&#110;&#64;&#99;&#105;&#110;&#101;&#109;&#101;&#120;&#46;&#110;&#101;&#116;">&#98;&#117;&#122;&#111;&#110;&#64;&#99;&#105;&#110;&#101;&#109;&#101;&#120;&#46;&#110;&#101;&#116;</a>.</React.Fragment>
              )}
            </p>
            <Button to="/" outline primary large>Volver al inicio</Button>
          </div>
        </div>
    );
  }

  renderForm() {
    return (
        <React.Fragment>
          {this.props.isProcessing && <Blocker/>}
          <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
            {fieldSets.map(fieldSet => (
                <fieldset key={fieldSet.label} className="mb-4">
                  <legend className="subtitle-2">{fieldSet.label}</legend>
                  <div className="form-row">
                    {fieldSet.fields.map(fieldName => {
                      const field = fields[fieldName];
                      const fieldValue = this.state[fieldName + '_value'];
                      const valid = this.state[fieldName + '_valid'];
                      const validationError = this.state[fieldName + '_validationError'];
                      const visible = this.state[fieldName + '_visible'] !== false;

                      if (!visible) {
                        return null;
                      }

                      return (
                          <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                            <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                      onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                      onBlur={() => this.validate(fieldName)}
                                      value={fieldValue}
                                      valid={valid}
                                      validationError={validationError}
                                      inputProps={field.inputProps || {}}
                                      choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, fieldValue))}
                                      id={field.id}
                                      required={field.required}
                            />
                          </div>
                      );
                    })}
                  </div>
                </fieldset>
            ))}

            <div className="text-right">
              <Button submit primary>Enviar</Button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  onChange(prop, value, element) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (value === '0' && (field.choices || field.getChoicesMethod)) {
      value = '';
    }

    if (field.type === 'checkbox') {
      value = element.checked;
    }

    this.setState((state) => ({[prop + '_value']: value}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {};

    Object.keys(fields).forEach(field => params[field] = this.state[field + '_value']);

    this.props.submit(params);
  }

  validateForm() {
    let validForm = true;

    Object.keys(fields).forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({
        [prop + '_valid']: valid,
        [prop + '_validationError']: validationError
      }));
      validForm &= valid !== false;
    });

    return validForm;
  }

  validate(prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({
      [prop + '_valid']: valid,
      [prop + '_validationError']: validationError
    }));
  }

  validateField(prop) {
    const value = this.state[prop + '_value'];
    const visible = this.state[prop + '_visible'] !== false;
    const {required, validationFns, validationMessage} = fields[prop];
    let valid = true;

    if (!visible || (!validationFns || !validationFns.length) || (!value && !required)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }
}

const mapStateToProps = state => ({
  areas: getAreas(state).sort(sortByName),
  cinemas: getCinemas(state).sort(sortByName),
  states: getStates(state).sort(sortByName),
  isProcessing: isJobsFormProcessing(state),
  isSubmitted: isJobsFormSubmitted(state),
});

const mapDispatchToProps = dispatch => ({
  show: () => dispatch(jobsFormShow()),
  submit: data => dispatch(jobsFormSubmit(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobsForm);